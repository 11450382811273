import { ReactNode } from "react";
import tw from "twin.macro";

const FaqCard = ({ children }: { children: ReactNode }) => {
	return (
		<div
			css={[
				tw`relative z-50`,
				tw`font-display text-bitters`,
				tw`bg-light-beige border-width[1px] border-bitters`,
				tw`p-6 mb-6`,
				tw`max-w-landing-page lg:(px-24 py-12)`,
			]}
		>
			{children}
		</div>
	);
};

export default FaqCard;
