import { ReactNode } from "react";
import tw from "twin.macro";

const FaqSection = ({
	children,
}: {
	children: ReactNode;
}) => {
	return (
		<div
			css={[
				tw`font-display text-bitters`,
				tw`bg-teal`,
				tw`p-6`,
				tw`relative lg:(flex flex-col items-center)`,
			]}
		>
			{children}
		</div>
	);
};

export default FaqSection;
